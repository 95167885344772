import { makeObservable, observable, computed, action } from "mobx";

interface IGridStoreOptions {
  defaultValues?: Record<string, any>;
  modifiers?: IModiifer[];
}

interface IModiifer {
  name: string;
  getter?: (value: any) => string;
  setter?: (value: any) => any;
}

class Form {
  filters: Record<string, any> = {};
  defaultValues: Record<string, any> = {};
  modifiers: IModiifer[] = [];

  constructor(options?: IGridStoreOptions) {
    if (options?.defaultValues) {
      this.defaultValues = options.defaultValues;
      this.filters = JSON.parse(JSON.stringify(options.defaultValues));
    }
    if (options?.modifiers) {
      this.modifiers = options.modifiers;
    }
    makeObservable(this, {
      filters: observable,
      searchParams: computed,
      handleChangeFilters: action,
      resetForm: action,
    });
  }

  handleChangeFilters = (name: string, value: any) => {
    this.filters[name] = value;
  };

  resetForm = () => {
    this.filters = JSON.parse(JSON.stringify(this.defaultValues));
  };

  get searchParams() {
    const params = new URLSearchParams();
    Object.entries(this.filters).forEach(([key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        if (this.modifiers) {
          const modifier = this.modifiers.find(
            (modifier) => modifier.name === key
          );
          if (modifier?.getter) {
            value = modifier.getter(value);
          }
        }
        params.set(key, value);
      }
    });
    return params ? `?${params.toString()}` : "";
  }
}

export default Form;
