import React, { useEffect, useState, useReducer } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import Table from "components/Table/Table";
import useFetch from "use-http";
import { formatDate } from "utils/formatDate";
import makeSearchString from "utils/makeSearchString";
import { useTranslation } from "react-i18next";
import UiPagination from "../../components/Pagination/Pagination";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

interface Props {}
interface IObject {
  [key: string]: any;
}

const INITIAL_STATE = {
  dateTime: null,
  ticketNumber: "",
  type: "",
  inn: "",
  seller: "",
  address: "",
  contact: "",
};

function formReducer(state: any, action: any) {
  switch (action.type) {
    case "set":
      return { ...state, [action.name]: action.value };
    case "reset":
      return INITIAL_STATE;
    default:
      return state;
  }
}

function Receipts(props: Props) {
  const {} = props;
  const [data, setData] = useState({ items: [], total: 0 });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const { get, response, loading, error } = useFetch();
  const [sort, setSort] = useState({ column: "dateTime", direction: "ASC" });
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const { t } = useTranslation();

  useEffect(() => {
    loadData();
  }, [page, sort, limit]);

  async function loadData(params?: IObject) {
    const search_params = params
      ? makeSearchString({
          ...params,
          sortBy: sort.column,
          direction: sort.direction,
        })
      : makeSearchString({
          ...(params || state),
          since: state.dateTime ? new Date(state.dateTime).getTime() : "",
          to: state.dateTime
            ? new Date(state.dateTime).getTime() + 60 * 60 * 24 * 1000
            : "",
          sortBy: sort.column,
          direction: sort.direction,
        });

    const initialData = await get(
      `/api/v1/receipts?page=${page}&limit=${limit}` +
        (search_params ? `&${search_params}` : "")
    );
    if (response.ok) setData(initialData);
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("Receipt number"),
        accessor: "ticketNumber",
        minWidth: 60,
        disableSortBy: true,
      },
      {
        Header: t("Date"),
        accessor: "dateTime",
        disableSortBy: true,
        Cell: ({ value }: { value: any }) => (
          <span>
            {formatDate(value, "dd.MM.yyy HH:mm")
              .split(" ")
              .map((item: string) => (
                <div>{item}</div>
              ))}
          </span>
        ),
      },
      {
        Header: t("TIN"),
        accessor: "inn",
        disableSortBy: true,
      },
      {
        Header: t("Cash Register"),
        accessor: "rnKkt",
        disableSortBy: true,
      },
      {
        Header: t("Address"),
        accessor: "tradePointAddress",
        minWidth: 200,
        disableSortBy: true,
      },
      {
        Header: t("Customer"),
        accessor: "contact",
        disableSortBy: true,
      },
      {
        Header: t("Receipt amount"),
        accessor: "totalSum",
        disableSortBy: true,
        Cell: ({ value }: { value: any }) => (
          <span>
            {(parseInt(value) / 100).toLocaleString("ru-RU", {
              maximumFractionDigits: 0,
            })}
          </span>
        ),
      },
      {
        Header: t("Tax deduction"),
        accessor: "taxDeduction",
        disableSortBy: true,
        Cell: ({ value }: { value: any }) => (
          <span>
            {(parseInt(value) / 100).toLocaleString("ru-RU", {
              maximumFractionDigits: 0,
            })}
          </span>
        ),
      },
    ],
    []
  );

  const handleChange = (name: string, value: string | Date | null) => {
    dispatch({ type: "set", name, value });
  };

  return (
    <div className="h-full">
      <div className="bg-white rounded-md m-4 p-2">
        <div className="flex gap-2 p-3 px-5">
          <div className="w-4/5">
            <div className="flex gap-2">
              {/* <div>
                <ToggleButtonGroup
                  size="small"
                  onChange={(e, value) => handleChange("type", value)}
                  value={state.type}
                  aria-label="Medium sizes"
                  exclusive
                >
                  <ToggleButton value="digital" key="left">
                    <ReceiptLongIcon />
                  </ToggleButton>
                  <ToggleButton value="cash" key="center">
                    <AlternateEmailIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div> */}
              <TextField
                id="outlined-basic"
                label={t("Receipt number")}
                variant="outlined"
                onChange={(e) => handleChange("ticketNumber", e.target.value)}
                value={state.ticketNumber}
                size="small"
                fullWidth
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={t("Date")}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  value={state.dateTime}
                  onChange={(value) => handleChange("dateTime", value)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
              <TextField
                id="outlined-basic"
                label={t("TIN")}
                variant="outlined"
                onChange={(e) => handleChange("inn", e.target.value)}
                value={state.inn}
                size="small"
                fullWidth
              />
              <TextField
                id="outlined-basic"
                label={t("Organization")}
                onChange={(e) => handleChange("seller", e.target.value)}
                value={state.seller}
                variant="outlined"
                size="small"
                fullWidth
              />
            </div>
          </div>
          <div className="w-1/5"></div>
        </div>
        <div className="flex gap-2 p-3 px-5">
          <div className="w-4/5">
            <div className="flex gap-2">
              <TextField
                id="outlined-basic"
                label={t("Phone number / E-mail")}
                onChange={(e) => handleChange("contact", e.target.value)}
                value={state.contact}
                variant="outlined"
                size="small"
                fullWidth
              />
              {/* <TextField
                id="outlined-basic"
                label="E-mail"
                variant="outlined"
                onChange={(e) => handleChange("email", e.target.value)}
                value={state.email}
                size="small"
                fullWidth
              /> */}
              <TextField
                id="outlined-basic"
                label={t("Address")}
                variant="outlined"
                onChange={(e) => handleChange("address", e.target.value)}
                value={state.address}
                size="small"
                fullWidth
              />
            </div>
          </div>
          <div className="w-1/5">
            <div className="flex gap-2">
              <div>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setPage(1);
                    loadData();
                  }}
                  fullWidth
                >
                  {t("Search")}
                </Button>
              </div>
              <div>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    dispatch({ type: "reset" });
                    loadData(INITIAL_STATE);
                  }}
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="m-4">
          <Table
            data={data?.items || []}
            columns={columns}
            loading={loading}
            handleSortChange={(column, direction) => {
              setSort({ column, direction });
            }}
            defaultSort={sort}
          />
          <UiPagination
            totalElements={data?.total || 1}
            page={page}
            size={limit}
            onPageChange={(page: number) => {
              setPage(page);
            }}
            onSizeChange={(size: number) => {
              setLimit(size);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Receipts;
