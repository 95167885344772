import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

interface Props {}

function Frod(props: Props) {
  const {} = props;

  return (
    <div className="min-h-full text-sm bg-white">
      <div className="p-3 px-10 bg-slate-100">
        <div className="text-slate-700 text-sm">Общие настройки</div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 leading-10">
          Периодичность проверки покупателей
        </div>
        <div className="w-2/5">
          <div className="w-[180px]">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                Переодичность
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={null}
                label="Статус"
                onChange={() => {}}
                fullWidth
              >
                <MenuItem value={1}>1 час</MenuItem>
                <MenuItem value={2}>2 часа</MenuItem>
                <MenuItem value={3}>3 часа</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 leading-10">
          Данные за какой период использовать
        </div>
        <div className="w-2/5">
          <div className="w-[180px]">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Период</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={null}
                label="Статус"
                onChange={() => {}}
                fullWidth
              >
                <MenuItem value={1}>1 час</MenuItem>
                <MenuItem value={2}>2 часа</MenuItem>
                <MenuItem value={3}>3 часа</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 leading-10">
          Допустимое количество дублей чеков
        </div>
        <div className="w-2/5">
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2">
          Допустимое количество аккаунтов для вывода на одни реквизиты
        </div>
        <div className="w-2/5">
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2 pr-2">
          Допустимое количество чеков на одного покупателя выданных за
        </div>
        <div className="flex gap-2 w-2/5">
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2 pr-2">
          Предельное количество чеков на одного покупателя выданных за
        </div>
        <div className="flex gap-2 w-2/5">
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2">
          Предельное количество аккаунтов для вывода на одни реквизиты
        </div>
        <div className="w-2/5">
          <div className="w-[80px]">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 bg-slate-100">
        <div className="text-slate-700 text-sm w-2/5">Данные за период</div>
        <div className="text-slate-700 text-sm grid grid-cols-4 gap-2 w-2/5">
          <div>24 часа</div>
          <div>7 дней</div>
          <div>30 дней </div>
          <div>365 дней </div>
        </div>
        <div className="text-slate-700 text-sm w-1/5"></div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2 pr-2">Допустимая сумма возвратов</div>
        <div className="grid grid-cols-4 gap-2 w-2/5">
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2 pr-2">Предельно допустимое количество возвратов</div>
        <div className="grid grid-cols-4 gap-2 w-2/5">
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2 pr-2">Допустимое количество чеков</div>
        <div className="grid grid-cols-4 gap-2 w-2/5">
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="flex p-3 px-10 border-b-[1px] border-slate-100">
        <div className="w-2/5 py-2 pr-2">Предельное количество чеков</div>
        <div className="grid grid-cols-4 gap-2 w-2/5">
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
          <div className="">
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
            />
          </div>
        </div>
        <div className="text-end w-1/5">
          <div>
            <Switch />
          </div>
        </div>
      </div>

      <div className="p-10 flex justify-end">
        <div className="flex">
          <div className="w-[200px] px-2">
          <Button variant="outlined" size="large" fullWidth>
              Очистить
            </Button>
          </div>
          <div className="w-[200px] px-2">
          <Button variant="contained" size="large" fullWidth>
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frod;
