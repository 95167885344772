import React, { useEffect } from "react";
import { useTable, Column, useSortBy } from "react-table";
import LinearProgress from "@mui/material/LinearProgress";
import SortIcon from "@mui/icons-material/Sort";

interface ITable {
  data: Array<{}>;
  columns: Array<Column>;
  loading?: boolean;
  handleSortChange?: (column: string, direction: string) => void;
  handleRowClick?: (row: any) => void;
  defaultSort?: { column: string; direction: string };
}

function Table(props: ITable) {
  const { data, columns, loading, handleSortChange, defaultSort } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({ columns, data, manualSortBy: true }, useSortBy);

  const { sortBy } = state;

  useEffect(() => {
    if (sortBy.length && !!handleSortChange) {
      handleSortChange(sortBy[0].id, sortBy[0].desc ? "DESC" : "ASC");
    } else if (!!defaultSort && !!handleSortChange) {
      handleSortChange(defaultSort.column, defaultSort.direction);
    } else if (!!handleSortChange) {
      handleSortChange("", "");
    }
  }, [sortBy]);

  return (
    <div className="relative">
      {loading && (
        <div className="absolute top-0 left-0 w-full bg-slate-100">
          <LinearProgress />
        </div>
      )}
      <table {...getTableProps()} className="table-auto w-full">
        <thead className="bg-slate-100 h-[60px] text-left sticky top-0">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="border-b-[1px] border-slate-200"
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={`px-4 text-sm text-slate-700 font-semibold`}
                >
                  <div
                    style={{
                      minWidth: column.minWidth ? column.minWidth : "",
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortIcon className="whitespace-nowrap" />
                        ) : (
                          <SortIcon className="rotate-180 whitespace-nowrap" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`bg-white hover:bg-slate-100 rounded-xl ${
                  props.handleRowClick ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (props.handleRowClick) {
                    props.handleRowClick(row.original);
                  }
                }}
              >
                {row.cells.map((cell, i, array) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`py-3 border-b-[1px] border-slate-300 text-[15px] ${
                        array.length === i + 1 && "w-[120px]"
                      }`}
                    >
                      <div
                        className="flex items-center min-h-[40px] h-full px-4 border-r-[2px]"
                        style={{
                          ...(cell.column.maxWidth && {
                            maxWidth: cell.column.maxWidth,
                          }),
                        }}
                      >
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
