import {
  FormControl,
  FormGroup,
  requiredValidator,
} from "@quantumart/mobx-form-validation-kit";
import { patternValidator } from "../utils/validators";
import { FormGroupWrapped } from "../types/formControl";
import { AppealStatusType, IAppeal, IAppealEditForm } from "../types/appeals";
import { makeObservable, observable } from "mobx";

type AppealFormType = FormGroupWrapped<Pick<IAppeal, "tin" | "status">>;

export class AppealCard {
  rawAppeal: IAppeal;
  form: AppealFormType;
  constructor(form: IAppeal) {
    makeObservable(this, {
      form: observable,
      rawAppeal: observable,
    });
    this.rawAppeal = form;
    this.form = new FormGroup({
      tin: new FormControl(form?.tin || "", {
        validators: [
          patternValidator("[0-9]{14}", () => "Неверный формат ИИН"),
        ],
      }),
      status: new FormControl<AppealStatusType>(form?.status || "NEW", {
        validators: [requiredValidator("Неверный статус")],
      }),
    });
  }

  getForm(): IAppealEditForm {
    return {
      id: this.rawAppeal.id,
      address: this.rawAppeal.address,
      tin: this.form.controls.tin.value,
      status: this.form.controls.status.value,
    };
  }
}
