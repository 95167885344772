import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";

export default function Hint({
  children,
  hint,
}: {
  children: React.ReactNode;
  hint: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const uid = uuidv4();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="flex items-center w-full">
      <Typography
        className="w-full"
        aria-owns={open ? uid : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Typography>
      <Popover
        id={uid}
        sx={{
          pointerEvents: "none",
          background: "transparent",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="bg-[rgba(0,0,0)] text-white text-[10px] p-2">
          {hint}
        </div>
      </Popover>
    </div>
  );
}
