import React from 'react'

interface Props {}

function Settings(props: Props) {
  const {} = props

  return (
    <div className="grid h-full place-content-center">
      <div className="text-lg">Settings</div>
    </div>
  )
}

export default Settings
