import { makeObservable, observable, action } from "mobx";
import { ILotteryResponse, ILotteryUserResponse } from "types";
import RootStore from "store";
import GridStore from 'store/classes/GridStore';

class LotteryStore extends GridStore {
  root
  lotery: ILotteryResponse | null = null;
  lotteryUsers: ILotteryUserResponse | null = null;

  constructor(root: typeof RootStore) {
    super();
    this.root = root;
    makeObservable(this, {
      lotery: observable,
      lotteryUsers: observable,
      loadLotteryDetail: action,
      loadLotteryUsers: action,
    });
  }

  loadLotteryDetail = async (id: string) => {
    const data = await this.root.api.get(`/api/v1/lotteries/${id}`);
    this.lotery = data;
  };

  loadLotteryUsers = async (id: string) => {
    const data = await this.root.api.get(`/api/v1/lotteries/${id}/users` + this.searchParams);
    this.lotteryUsers = data;
  }
}

export default LotteryStore;
