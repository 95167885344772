interface IObject {
  [key: string]: any;
}

export default function makeSearchString(obj: IObject): string {
  const result: string[] = [];
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value !== undefined && value !== null && value !== '') {
      result.push(`${key}=${value}`);
    }
  });
  return result.join('&');
}
