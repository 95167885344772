export type AppealAcceptType = "QR" | "URL" | "PHOTO";
export type AppealStatusType =
  | "NEW"
  | "IN_WORK"
  | "SENT_PENALTY"
  | "SENT_WARNING"
  | "CLOSED";
export type AppealTextType = "Невыдача чека" | string;

export const AppealStatusTypeMapping: Partial<Record<AppealStatusType, any>> = {
  NEW: "information",
  IN_WORK: "success",
  CLOSED: "neutral",
  SENT_PENALTY: "danger",
  SENT_WARNING: "warning",
};

export const AppealAcceptTypeMapping: Record<AppealAcceptType, any> = {
  QR: "QR code",
  URL: "URL address",
  PHOTO: "By photo",
};

export interface IAppeal {
  id: string | number;
  createdDate: string;
  appealText: AppealTextType;
  status: AppealStatusType;
  acceptType: AppealAcceptType;
  tin: string;
  url: string;
  coords: string;
  address: string;
  sellerName: string;
  photos: string[];
}

export interface IAppealEditForm
  extends Pick<IAppeal, "id" | "tin" | "address" | "status"> {}
