import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as Chevron } from "assets/svg/chevron.svg";

interface Props {
  totalElements: number;
  page: number;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  size: number;
}

export const PAGE_SIZES = [10, 20, 50, 100];

export default function UiPagination({
  totalElements,
  size,
  onSizeChange,
  onPageChange,
  page,
}: Props) {
  const pagesCount = totalElements ? Math.ceil(totalElements / size) : 1;
  const prevDisabled = page === 1;
  const nextDisabled = page === pagesCount;

  return (
    <div className="sticky bottom-0 p-2.5 bg-[#fff]">
      <FormControl size="small" className="w-full">
        <div className="flex items-center gap-5 ml-auto">
          <Chevron
            className="cursor-pointer"
            style={{ color: prevDisabled ? "#939AA4" : "#000" }}
            onClick={() => !prevDisabled && onPageChange(page - 1)}
          />
          <Select
            labelId="pages-select-label"
            id="pages-select"
            onChange={(e) => onSizeChange(Number(e.target.value) || 20)}
            value={size}
          >
            {PAGE_SIZES.map((s) => (
              <MenuItem value={s} key={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
          <Chevron
            style={{ color: nextDisabled ? "#939AA4" : "#000" }}
            className="rotate-180 cursor-pointer"
            onClick={() => !nextDisabled && onPageChange(page + 1)}
          />
        </div>
      </FormControl>
    </div>
  );
}
