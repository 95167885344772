import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import Table from "components/Table/Table";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { formatDate } from "utils/formatDate";
import Hint from "components/Hint/Hint";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Alert from "@mui/material/Alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import store from "store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ReactComponent as PRE_REG_ICON } from "assets/svg/PRE_REG.svg";
import { ReactComponent as BLOCK_FRAUD_ICON } from "assets/svg/BLOCK_FRAUD.svg";
import { ReactComponent as BLOCKED_ICON } from "assets/svg/BLOCKED.svg";
import { ReactComponent as CODE_GEN_ICON } from "assets/svg/CODE_GEN.svg";
import { ReactComponent as FULL_REG_ICON } from "assets/svg/FULL_REG.svg";
import { ReactComponent as PHONE_REG_ICON } from "assets/svg/PHONE_REG.svg";
import { ReactComponent as REG_NO_END_ICON } from "assets/svg/REG_NO_END.svg";
import { ReactComponent as LockIcon } from "assets/svg/lock.svg";
import { ReactComponent as UnlockIcon } from "assets/svg/unlock.svg";
import UiBadge from "../../components/Badge/Badge";
import UiPagination from "../../components/Pagination/Pagination";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

function Users() {
  const { users, filters, handleChangeFilters, loadUsers, unblockUser } =
    store.users;
  const [currentUser, setCurrentUser] = useState<null | any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [filters.limit, filters.page]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          if (value === "PRE_REGISTRATION")
            return (
              <Hint hint={t("hints.PRE_REGISTRATION")}>
                <UiBadge type="warning">
                  <PRE_REG_ICON
                    style={{ color: "var(--color-warning-dark)" }}
                    className="inline mr-2"
                  />
                  PRE_REG
                </UiBadge>
                {/*<span className="p-2 px-4 bg-yellow-400 rounded-2xl text-white text-xs whitespace-nowrap"></span>*/}
              </Hint>
            );
          if (value === "CODE_GENERATED")
            return (
              <Hint hint={t("hints.CODE_GENERATED")}>
                <UiBadge type="warning">
                  <CODE_GEN_ICON
                    style={{ color: "var(--color-warning-dark)" }}
                    className="inline mr-2"
                  />
                  CODE_GEN
                </UiBadge>
              </Hint>
            );
          if (value === "REGISTRATION_NOT_COMPLETED")
            return (
              <Hint hint={t("hints.REGISTRATION_NOT_COMPLETED")}>
                <UiBadge type="warning">
                  <REG_NO_END_ICON
                    style={{ color: "var(--color-warning-dark)" }}
                    className="inline mr-2"
                  />
                  REG_NO_END
                </UiBadge>
              </Hint>
            );
          if (value === "PHONE_REGISTRATION_COMPLETED")
            return (
              <Hint hint={t("hints.PHONE_REGISTRATION_COMPLETED")}>
                <UiBadge type="success">
                  <PHONE_REG_ICON
                    style={{ color: "var(--color-success-dark)" }}
                    className="inline mr-2"
                  />
                  PHONE_REG
                </UiBadge>
              </Hint>
            );
          if (value === "FULL_REGISTRATION_COMPLETED")
            return (
              <Hint hint={t("hints.FULL_REGISTRATION_COMPLETED")}>
                <UiBadge type="success">
                  <FULL_REG_ICON
                    style={{ color: "var(--color-success-dark)" }}
                    className="inline mr-2"
                  />
                  FULL_REG
                </UiBadge>
              </Hint>
            );
          if (value === "EMAIL_REGISTRATION_COMPLETED")
            return (
              <Hint hint={t("hints.EMAIL_REGISTRATION_COMPLETED")}>
                <UiBadge type="success">
                  <PHONE_REG_ICON
                    style={{ color: "var(--color-success-dark)" }}
                    className="inline mr-2"
                  />
                  EMAIL_REGISTRATION_COMPLETED
                </UiBadge>
              </Hint>
            );
          if (value === "BLOCKED")
            return (
              <Hint hint={t("hints.BLOCKED")}>
                <UiBadge type="danger">
                  <BLOCKED_ICON
                    style={{ color: "var(--color-danger-dark)" }}
                    className="inline mr-2"
                  />
                  BLOCKED
                </UiBadge>
              </Hint>
            );
          if (value === "BLOCKED_FRAUD")
            return (
              <Hint hint={t("hints.BLOCKED_FRAUD")}>
                <UiBadge type="danger">
                  <BLOCK_FRAUD_ICON
                    style={{ color: "var(--color-danger-dark)" }}
                    className="inline mr-2"
                  />
                  BLOCKED_FRAUD
                </UiBadge>
              </Hint>
            );

          return <span>{value}</span>;
        },
      },
      {
        Header: t("Registration date"),
        accessor: "regDate",
        minWidth: 155,
        Cell: ({ value }: { value: string }) => {
          return <span>{formatDate(value)}</span>;
        },
      },
      {
        Header: t("Phone number"),
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: t("Receipts count"),
        accessor: "receiptCount",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span></span>;
          return <span>{value.toLocaleString("ru-RU")}</span>;
        },
      },
      {
        Header: t("Lottery wins count"),
        accessor: "winsCount",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span></span>;
          if (value === 0) return <span>0</span>;
          return <span>{value.toLocaleString("ru-RU")}</span>;
        },
      },
      {
        Header: t("Total tax deduction"),
        accessor: "totalTaxDeduction",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span></span>;
          return (
            <span>
              {(value / 100).toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              })}
            </span>
          );
        },
      },
      {
        Header: t("Withdrawn"),
        accessor: "withdrawalCounter",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span></span>;
          return (
            <span>
              {(value / 100).toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              })}
            </span>
          );
        },
      },
      {
        Header: t("Balance"),
        accessor: "balance",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span></span>;
          return (
            <span>
              {(value / 100).toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              })}
            </span>
          );
        },
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({
          row,
        }: {
          row?: { original?: { status?: string; id?: string } };
        }) => {
          return (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState: any) => (
                <div>
                  <IconButton {...bindTrigger(popupState)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    {(row?.original?.status === "PRE_REGISTRATION" ||
                      row?.original?.status === "CODE_GENERATED" ||
                      row?.original?.status === "REGISTRATION_NOT_COMPLETED" ||
                      row?.original?.status ===
                        "PHONE_REGISTRATION_COMPLETED" ||
                      row?.original?.status ===
                        "EMAIL_REGISTRATION_COMPLETED" ||
                      row?.original?.status ===
                        "FULL_REGISTRATION_COMPLETED") && (
                      <Button
                        sx={{
                          padding: "8px 15px",
                          color: "#444",
                        }}
                        onClick={() => {
                          setCurrentUser(row?.original);
                          popupState.close();
                        }}
                      >
                        <LockIcon />
                        <span className="pl-4">{t("Block user")}</span>
                      </Button>
                    )}
                    {(row?.original?.status === "BLOCKED_FRAUD" ||
                      row?.original?.status === "BLOCKED") && (
                      <Button
                        sx={{
                          padding: "8px 15px",
                          color: "#444",
                        }}
                        onClick={() => {
                          if (row?.original?.id) {
                            unblockUser(row.original.id);
                            popupState.close();
                            loadUsers();
                          }
                        }}
                      >
                        <UnlockIcon />
                        <span className="pl-4">{t("Unblock user")}</span>
                      </Button>
                    )}
                  </Popover>
                </div>
              )}
            </PopupState>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="h-full">
      <UsersFilters />
      <div className="w-full">
        <div className="m-4">
          <Table
            data={users?.items || []}
            columns={columns}
            handleSortChange={(column, direction) => {
              if (column && direction) {
                handleChangeFilters("sortBy", column);
                handleChangeFilters("direction", direction);
              }
              loadUsers();
            }}
          />
          <UiPagination
            totalElements={users?.total || 1}
            page={filters.page}
            size={filters.limit}
            onPageChange={(page: number) => {
              handleChangeFilters("page", page);
            }}
            onSizeChange={(size: number) => {
              handleChangeFilters("limit", size);
            }}
          />
        </div>
      </div>
      <BlockUserModal
        handleClose={() => setCurrentUser(null)}
        user={currentUser}
      />
    </div>
  );
}

const UsersFilters = observer(() => {
  const { filters, handleChangeFilters, loadUsers, resetForm } = store.users;
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-md m-4 p-2">
      <div className="grid grid-cols-5 gap-4 p-3 px-4">
        <div>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Статус"
              onChange={(e) => handleChangeFilters("filter", e.target.value)}
              value={filters.filter}
              fullWidth
            >
              <MenuItem value="CODE_GENERATED">CODE_GENERATED</MenuItem>
              <MenuItem value="PHONE_REGISTRATION_COMPLETED">
                PHONE_REGISTRATION_COMPLETED
              </MenuItem>
              <MenuItem value="REGISTRATION_NOT_COMPLETED">
                REGISTRATION_NOT_COMPLETED
              </MenuItem>
              <MenuItem value="FULL_REGISTRATION_COMPLETED">
                FULL_REGISTRATION_COMPLETED
              </MenuItem>
              <MenuItem value="BLOCKED">BLOCKED</MenuItem>
              <MenuItem value="BLOCKED_FRAUD">BLOCKED_FRAUD</MenuItem>
              <MenuItem value="EMAIL_REGISTRATION_COMPLETED">
                EMAIL_REGISTRATION_COMPLETED
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={t("Registration date")}
              inputFormat="dd.MM.yyyy"
              value={filters.reg_date}
              onChange={(value) => handleChangeFilters("reg_date", value)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
        <div>
          <TextField
            id="outlined-basic"
            label={t("Phone")}
            variant="outlined"
            size="small"
            onChange={(e) => handleChangeFilters("phone", e.target.value)}
            value={filters.phone}
            fullWidth
          />
        </div>
        <div className="flex gap-2">
          <div>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                handleChangeFilters("page", 1);
                loadUsers();
              }}
              fullWidth
            >
              {t("Search")}
            </Button>
          </div>
          <div>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                resetForm();
                loadUsers();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

const BlockUserModal = ({
  handleClose,
  user,
}: {
  handleClose: () => void;
  user: any;
}) => {
  const { blockUser } = store.users;
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleBlock = async () => {
    setLoading(true);
    try {
      blockUser(user?.id, comment);
      handleClose();
    } catch (e: any) {
      setError(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={!!user} onClose={handleClose}>
      <DialogTitle>Блокировка покупателя</DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <div className="pt-2 pb-8">
          <TextField
            label="Причина блокировки"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
          />
        </div>
        {error && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <div className="pb-4">
          <Button
            onClick={handleBlock}
            color="primary"
            variant="contained"
            size="large"
            disabled={loading || !comment}
          >
            Заблокировать
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            size="large"
            variant="text"
          >
            Отмена
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default observer(Users);
