import { makeObservable, observable, action } from "mobx";
import { IUsersResponse, IUsersRequest } from "types";
import RootStore from "store";
import { formatDate } from "utils/formatDate";
import GridStore from "./classes/GridStore";

class UsersStore extends GridStore {
  root;
  users: IUsersResponse | null = null;

  constructor(root: typeof RootStore) {
    super({
      defaultValues: {
        reg_date: null,
        filter: "",
        phone: "",
        sortBy: "regDate",
        direction: "DESC",
        limit: 20,
        page: 1,
      },
      modifiers: [
        {
          name: "reg_date",
          getter: (value: Date) => formatDate(new Date(value), "yyyy-MM-dd"),
        },
      ],
    });
    this.root = root;
    makeObservable(this, {
      users: observable,
      loadUsers: action,
      blockUser: action,
      unblockUser: action,
    });
  }

  loadUsers = async () => {
    const data = await this.root.api.get(`/api/v1/user` + this.searchParams);
    this.users = data;
  };

  blockUser = async (id: number, message: string) => {
    await this.root.api.put(`/api/v1/user/lock?id=${id}`, {});
    this.loadUsers();
  };

  unblockUser = async (id: number | string) => {
    await this.root.api.put(`/api/v1/user/unlock?id=${id}`, {});
    this.loadUsers();
  };
}

export default UsersStore;
