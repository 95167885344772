import React from "react";

export interface ValueCardProps {
  value: string | number;
  header?: string;
  imgName?: string;
  imgColor?: string;
}

export const ValueCard: React.FC<ValueCardProps> = ({
  value = "",
  header = "",
  imgName,
  imgColor = "#9FB8EB",
}) => {
  return (
    <div className="flex items-center flex-1 p-[16px] gap-[24px] border-[1px] rounded-[8px] w-fit">
      {imgName && (
        <div
          style={{ backgroundColor: imgColor }}
          className="flex shrink-0 items-center justify-center w-[48px] h-[48px] rounded-[4px]"
        >
          <img src={require(`assets/svg/${imgName}.svg`)} alt="" />
        </div>
      )}
      <div className="flex flex-col text-[#59606A]">
        <span className="text-[16px] font-[400]">{header}</span>
        <span className="text-[14px] font-[600]">{value}</span>
      </div>
    </div>
  );
};
