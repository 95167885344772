import * as React from "react";

export type BadgeTypes =
  | "success"
  | "neutral"
  | "danger"
  | "accent"
  | "warning"
  | "information"
  | "primary";

interface Props {
  children: React.ReactNode;
  type?: BadgeTypes;
}

export default function UiBadge({ children, type = "neutral" }: Props) {
  return (
    <span
      className={`block px-[12px] py-[4px] rounded-[4px] text-white text-xs whitespace-nowrap font-[600] text-center`}
      style={{
        color: `var(--color-${type}-dark)`,
        backgroundColor: `var(--color-${type}-light)`,
      }}
    >
      {children}
    </span>
  );
}
