import { parseISO, format, isDate } from "date-fns";

export function formatDate(
  date?: string | Date | null,
  formatStr = "dd.MM.yyyy"
): string {
  if (!date || !isDate(new Date(date))) return "";
  if (date instanceof Date) return format(date, formatStr);
  if (isNaN(new Date(date).getDay())) return "";

  return format(parseISO(date), formatStr);
}
