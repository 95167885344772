import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Table from "components/Table/Table";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Hint from "components/Hint/Hint";
import store from "store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  ValueCard,
  ValueCardProps,
} from "../../components/ValueCard/ValueCard";
import {
  AppealAcceptType,
  AppealAcceptTypeMapping,
  AppealStatusType,
  AppealStatusTypeMapping,
  IAppeal,
} from "../../types/appeals";
import UiBadge from "../../components/Badge/Badge";
import UiPagination from "../../components/Pagination/Pagination";
import AppealModal from "./AppealsModal";

function Appeals() {
  const {
    appeals,
    filters,
    stats,
    handleChangeFilters,
    loadAppeals,
    loadStats,
  } = store.appeals;
  const [currentAppealId, setCurrentAppealId] = useState<null | IAppeal["id"]>(
    null
  );
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    loadAppeals();
    loadStats();
  }, []);

  const computedStats = React.useMemo(
    () => [
      {
        value: stats?.NEW || 0,
        header: t("Open"),
        imgName: "new_letter",
      },
      {
        value: stats?.IN_WORK || 0,
        header: t("IN_WORK"),
        imgName: "search",
      },
      {
        value: (stats?.SENT_WARNING || 0) + (stats?.CLOSED || 0),
        header: t("CLOSED"),
        imgName: "check-mark",
      },
    ],
    [stats, language]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        minWidth: 100,
        maxWidth: 250,
        Cell: ({ value }: { value: AppealStatusType }) => {
          if (Object.keys(AppealStatusTypeMapping).includes(value)) {
            return (
              <Hint hint={t(value)}>
                <UiBadge type={AppealStatusTypeMapping[value]}>
                  {t(value)}
                </UiBadge>
              </Hint>
            );
          }

          return <span>{value}</span>;
        },
      },
      {
        Header: t("Violation type"),
        accessor: "appealText",
        disableSortBy: true,
        minWidth: 300,
        Cell: ({ value }: { value: IAppeal["appealText"] }) => {
          return <div className=" text-[#4F79D0]">{value}</div>;
        },
      },
      {
        Header: t("Details"),
        accessor: "tin",
        disableSortBy: true,
        minWidth: 300,
        Cell: (props: any & { row: { original: IAppeal } }) => {
          return (
            <div className="flex flex-col justify-center">
              <span>{props.row.original.url}</span>
              <span>{props.row.original.tin}</span>
              <span>{props.row.original.sellerName}</span>
              <span>{props.row.original.address}</span>
            </div>
          );
        },
      },
      {
        Header: t("Acceptance type"),
        accessor: "acceptType",
        disableSortBy: true,
        minWidth: 240,
        Cell: ({ value }: { value: AppealAcceptType }) => {
          if (!value) return <span></span>;
          return <div>{t(AppealAcceptTypeMapping[value])}</div>;
        },
      },
    ],
    [language]
  );

  return (
    <div className="h-full">
      <AppealsValues values={computedStats} />
      <AppealsFilters />
      <div className="w-full">
        <div className="m-4">
          <Table
            data={appeals?.data || []}
            columns={columns}
            handleRowClick={(row) => setCurrentAppealId(row.id)}
          />
          <UiPagination
            totalElements={appeals?.totalElements || 1}
            page={filters.page}
            size={filters.size}
            onPageChange={(page: number) => {
              handleChangeFilters("page", page);
              loadAppeals();
            }}
            onSizeChange={(size: number) => {
              handleChangeFilters("size", size);
              loadAppeals();
            }}
          />
        </div>
      </div>
      <AppealModal
        handleClose={() => {
          setCurrentAppealId(null);
          loadAppeals();
        }}
        appealId={currentAppealId}
      />
    </div>
  );
}

const AppealsFilters = observer(() => {
  const { filters, handleChangeFilters, loadAppeals, resetForm } =
    store.appeals;
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-md m-4 p-2">
      <div className="grid grid-cols-5 gap-4 p-3 px-4">
        <div>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("Status")}
              onChange={(e) => handleChangeFilters("status", e.target.value)}
              value={filters.status}
              fullWidth
            >
              <MenuItem value="">{t("ALL")}</MenuItem>
              <MenuItem value="NEW">{t("NEW")}</MenuItem>
              <MenuItem value="IN_WORK">{t("IN_WORK")}</MenuItem>
              <MenuItem value="SENT_PENALTY">{t("SENT_PENALTY")}</MenuItem>
              <MenuItem value="SENT_WARNING">{t("SENT_WARNING")}</MenuItem>
              <MenuItem value="CLOSED">{t("CLOSED")}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl fullWidth size="small">
            <InputLabel id="violation-label">{t("Violation type")}</InputLabel>
            <Select
              labelId="violation-label"
              id="violation-select"
              label={t("Violation type")}
              onChange={(e) => handleChangeFilters("text", e.target.value)}
              value={filters.text}
              fullWidth
            >
              <MenuItem value="">{t("ALL")}</MenuItem>
              <MenuItem value="Failure to issue cash register receipt">
                Failure to issue cash register receipt
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl fullWidth size="small">
            <InputLabel id="acceptance-type-label">
              {t("Acceptance type")}
            </InputLabel>
            <Select
              labelId="acceptance-type-label"
              id="acceptance-type-select"
              label={t("Acceptance type")}
              onChange={(e) =>
                handleChangeFilters("acceptType", e.target.value)
              }
              value={filters.acceptType}
              fullWidth
            >
              <MenuItem value="">{t("ALL")}</MenuItem>
              <MenuItem value="QR">{t("QR code")}</MenuItem>
              <MenuItem value="URL">{t("URL address")}</MenuItem>
              <MenuItem value="PHOTO">{t("By photo")}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flex gap-2">
          <div>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                handleChangeFilters("page", 1);
                loadAppeals();
              }}
              fullWidth
            >
              {t("Search")}
            </Button>
          </div>
          <div>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                resetForm();
                loadAppeals();
              }}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

const AppealsValues: React.FC<{ values: ValueCardProps[] }> = (cards) => {
  return (
    <div className="flex gap-[16px] bg-white rounded-md m-4 p-[16px]">
      {cards.values.map((card) => (
        <ValueCard {...card} key={card.header} />
      ))}
    </div>
  );
};

export default observer(Appeals);
