import { Options } from "use-http";

export const options: Partial<Options> = {
  // @ts-ignore
  cachePolicy: "no-cache",
  interceptors: {
    // every time we make an http request, this will run 1st before the request is made
    // url, path and route are supplied to the interceptor
    // request options can be modified and must be returned
    request: async ({ options, url, path, route }) => {
      const token = localStorage.getItem("TOKENS");
      const parse_token = JSON.parse(token || "{}");
      // if (isExpired(token)) {
      //   token = await getNewToken()
      //   setToken(token)
      // }
      const headers = new Headers();
      headers.set("Authorization", `Bearer ${parse_token?.accessToken}`);
      headers.set("Content-Type", `application/json`);
      if (options) options.headers = headers;
      return options;
    },
    // every time we make an http request, before getting the response back, this will run
    response: async ({ response }) => {
      if (response.status === 401) {
        if (inIframe() && window.parent) {
          window.parent.postMessage("tokenExpired", "*");
        } else {
          window.location.href = "/login";
        }
      }
      return response;
    },
  },
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
