import {
  FormControl,
  ValidationEventTypes,
} from "@quantumart/mobx-form-validation-kit";

export const onlyNumberValidator = (message: string) => {
  return async (validationState: FormControl<any>) => {
    if (isNaN(validationState.value))
      return [
        {
          message: message as string,
          type: ValidationEventTypes.Error,
        },
      ];

    return [];
  };
};

export const maxValueValidator = (
  value: (() => number) | number,
  message: () => string = () => ""
) => {
  return async (validationState: FormControl<any>) => {
    if (value === null) return [];
    if (typeof value === "function") value = value();
    if (Number(validationState.value) > value)
      return [
        {
          message: message() as string,
          type: ValidationEventTypes.Error,
        },
      ];

    return [];
  };
};

export const minValueValidator = (
  value: (() => number) | number | null,
  message: string = ""
) => {
  return async (validationState: FormControl<any>) => {
    if (value === null) return [];
    if (typeof value === "function") value = value();
    if (Number(validationState.value) < value)
      return [
        {
          message: message as string,
          type: ValidationEventTypes.Error,
        },
      ];

    return [];
  };
};

export const patternValidator = (
  pattern: string | RegExp | null,
  message: () => string = () => ""
) => {
  return async (validationState: FormControl<any>) => {
    if (validationState.value === null) return [];
    if (
      !new RegExp("^X$".replace("X", String(pattern))).test(
        validationState.value
      )
    )
      return [
        {
          message: message() as string,
          type: ValidationEventTypes.Error,
        },
      ];

    return [];
  };
};
