import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useForm, Controller } from "react-hook-form";
import useFetch from "use-http";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ILotteryResponse } from "../../types";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

interface Props {
  onClose?: () => void;
  currentLotteryId: number | null;
}

const schema = yup
  .object({
    lotteryPeriod: yup.string().when("lotteryType", {
      is: "REGULAR",
      then: yup.string().required("Choose a lottery period"),
    }),
    lotteryType: yup.string().required("Select lottery type"),
    startDate: yup
      .date()
      .typeError("Select the start date of the lottery")
      .required("Select the start date of the lottery"),
    endDate: yup
      .date()
      .nullable()
      .when("lotteryType", {
        is: "IRREGULAR",
        then: yup
          .date()
          .typeError("Select the end date of the lottery")
          .required("Select the end date of the lottery"),
      }),
    winnersCount: yup
      .number()
      .nullable()
      .typeError("Required field. Only numeric value")
      .required("Enter the number of winners"),
    budget: yup
      .number()
      .nullable()
      .typeError("Required field. Only numeric value")
      .required("Enter the prize fund"),
    moneyPrize: yup
      .number()
      .nullable()
      .typeError("Required field. Only numeric value")
      .required("Enter the prize amount"),
    message: yup.string().required("Enter the message"),
    customRules: yup.string().required("Enter the rules"),
    name: yup.string().required("Enter the name"),
    description: yup.string().required(""),
  })
  .required();

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

type LotteryFormType = Partial<Nullable<ILotteryResponse>>;

const defaultValues: LotteryFormType = {
  lotteryPeriod: "",
  lotteryType: "",
  startDate: null,
  endDate: null,
  winnersCount: null,
  budget: null,
  moneyPrize: null,
  message: "",
  customRules: "",
  name: "",
  description: "",
};

function LotteryModal(props: Props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { get, post, put, response } = useFetch();

  useEffect(() => {
    const getLotteryData = async () =>
      await get(`/api/v1/lotteries/${props.currentLotteryId}`);
    getLotteryData()
      .catch((e) => console.error(e))
      .then((data: ILotteryResponse) => {
        setValue("lotteryPeriod", data.lotteryPeriod);
        setValue("lotteryType", data.lotteryType);
        setValue("startDate", data.startDate);
        setValue("endDate", data.endDate);
        setValue("winnersCount", data.winnersCount);
        setValue("budget", data.budget ? data.budget / 100 : 0);
        setValue("moneyPrize", data.moneyPrize ? data.moneyPrize / 100 : 0);
        setValue("message", data.message);
        setValue("customRules", data.customRules);
        setValue("name", data.name);
        setValue("description", data.description);
      });
  }, [props.currentLotteryId]);

  async function sendData(data: any) {
    await put(`/api/v1/lotteries/${props.currentLotteryId}`, data);
    if (response.ok && onClose) onClose();
  }

  const onSubmit = (data: any) => {
    sendData({
      ...data,
      budget: data.budget ? parseInt(data.budget) * 100 : 0,
      moneyPrize: data.moneyPrize ? parseInt(data.moneyPrize) * 100 : 0,
      startDate: data.startDate?.toISOString(),
      endDate: data.endDate?.toISOString(),
    });
  };

  const onError = (errors: any) => {
    if (
      errors?.name ||
      errors?.description ||
      errors?.startDate ||
      errors?.endDate ||
      errors?.loteryType ||
      errors?.loteryPeriod
    ) {
      setActiveStep(0);
    } else if (errors?.winnersCount || errors?.budget || errors?.moneyPrize) {
      setActiveStep(1);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? 0 : prevActiveStep - 1
    );
  };

  return (
    <div className="bg-white absolute top-[50px] ml-[-360px] left-1/2 w-[710px] min-h-[400px] rounded-sm shadow-md">
      <div className="flex content-between">
        <h2 className="text-2xl px-8 py-4">{t("Edit")}</h2>
        {/* <CloseIcon /> */}
      </div>
      <div className="px-8 py-4">
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key={0}>
            <StepLabel>{t("Lottery")}</StepLabel>
            <StepContent>
              <div className="pl-24 mt-[-30px]">
                <div className="w-[500px]">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Name")}
                        variant="outlined"
                        error={!!fieldState.error}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                  <p className="text-red-500">{errors?.name?.message}</p>
                </div>
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Description")}
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        error={!!fieldState.error}
                        {...field}
                      />
                    )}
                  />
                  <p className="text-red-500">{errors?.description?.message}</p>
                </div>
                <div className="flex gap-4 pt-4 w-[500px]">
                  <div className="w-1/2">
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label={t("Start date")}
                            inputFormat="dd.MM.yyyy"
                            {...field}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!fieldState.error}
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-red-500">{errors?.startDate?.message}</p>
                  </div>
                  <div className="w-1/2">
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label={t("End date")}
                            inputFormat="dd.MM.yyyy"
                            {...field}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!fieldState.error}
                                size="small"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-red-500">{errors?.endDate?.message}</p>
                  </div>
                </div>
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="lotteryType"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl>
                        <FormLabel error={!!fieldState.error} id="lotery-type">
                          {t("Lottery type")}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="row-radio-buttons-group"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            trigger(["endDate", "lotteryPeriod"]);
                          }}
                        >
                          <FormControlLabel
                            value="REGULAR"
                            control={<Radio />}
                            label={t("Regular")}
                          />
                          <FormControlLabel
                            value="IRREGULAR"
                            control={<Radio />}
                            label={t("One-time")}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <p className="text-red-500">{errors?.lotteryType?.message}</p>
                </div>
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="lotteryPeriod"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          {t("Lottery period")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label={t("Lottery period")}
                          error={!!fieldState.error}
                          {...field}
                          fullWidth
                        >
                          <MenuItem value="DAILY">{t("Daily")}</MenuItem>
                          <MenuItem value="WEEKLY">{t("Weekly")}</MenuItem>
                          <MenuItem value="MONTHLY">{t("Monthly")}</MenuItem>
                          <MenuItem value="QUARTERLY">
                            {t("Quarterly")}
                          </MenuItem>
                          <MenuItem value="YEARLY">{t("Yearly")}</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <p className="text-red-500">
                    {errors?.lotteryPeriod?.message}
                  </p>
                </div>
              </div>
            </StepContent>
          </Step>
          <Step key={1}>
            <StepLabel>{t("Conditions")}</StepLabel>
            <StepContent>
              <div className="pl-24 mt-[-30px]">
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Message")}
                        variant="outlined"
                        error={!!fieldState.error}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-red-500">{errors?.message?.message}</p>
                </div>
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="customRules"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Custom rules")}
                        variant="outlined"
                        error={!!fieldState.error}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-red-500">{errors?.customRules?.message}</p>
                </div>
              </div>
            </StepContent>
          </Step>
          <Step key={2}>
            <StepLabel>{t("Prize")}</StepLabel>
            <StepContent>
              <div className="pl-24 mt-[-30px]">
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="winnersCount"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Winners count")}
                        variant="outlined"
                        error={!!fieldState.error}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-red-500">
                    {errors?.winnersCount?.message}
                  </p>
                </div>
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="budget"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Budget")}
                        variant="outlined"
                        error={!!fieldState.error}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-red-500">{errors?.budget?.message}</p>
                </div>
                <div className="pt-4 w-[500px]">
                  <Controller
                    name="moneyPrize"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t("Money prize")}
                        variant="outlined"
                        error={!!fieldState.error}
                        {...field}
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-red-500">{errors?.moneyPrize?.message}</p>
                </div>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </div>
      <div className="p-8 flex gap-4 justify-end">
        <Button variant="outlined" onClick={handleBack}>
          {t("Back")}
        </Button>
        {activeStep !== 2 && (
          <Button variant="contained" onClick={handleNext}>
            {t("Next")}
          </Button>
        )}
        {activeStep === 2 && (
          <Button variant="contained" onClick={handleSubmit(onSubmit, onError)}>
            {t("Update")}
          </Button>
        )}
        <Button
          variant="text"
          onClick={() => {
            onClose && onClose();
          }}
        >
          {t("Cancel")}
        </Button>
      </div>
    </div>
  );
}

export default LotteryModal;
