import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller, useWatch } from "react-hook-form";
import useFetch from "use-http";
import { formatDate } from "utils/formatDate";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

interface Props {
  onClose?: () => void;
  deduction?: any;
}

function DeductionModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, deduction } = props;
  const [activeStep, setActiveStep] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: deduction
      ? deduction
      : {
          value: "",
          fromDate: null,
          toDate: null,
        },
  });
  const fromDate = useWatch({ control, name: "fromDate" });
  const { post, put, response, loading, error } = useFetch();

  async function sendData(data: any) {
    if (deduction) {
      const res = await put(`/api/v1/deductions/${deduction.id}`, {
        value: data.value,
        fromDate: formatDate(new Date(data.fromDate), "yyyy-MM-dd"),
        toDate: formatDate(new Date(data.toDate), "yyyy-MM-dd"),
      });
      if (response.ok) {
        onClose && onClose();
      }
    } else {
      const res = await post(`/api/v1/deductions`, {
        value: data.value,
        fromDate: formatDate(new Date(data.fromDate), "yyyy-MM-dd"),
        toDate: formatDate(new Date(data.toDate), "yyyy-MM-dd"),
      });
      if (response.ok) {
        onClose && onClose();
      }
    }
  }

  const onSubmit = (data: any) => {
    sendData({
      ...data,
      fromDate: data.fromDate
        ? formatDate(new Date(data.fromDate), "yyyy-MM-dd")
        : null,
      toDate: data.toDate
        ? formatDate(new Date(data.toDate), "yyyy-MM-dd")
        : null,
    });
  };

  return (
    <div className="bg-white absolute top-[50px] ml-[-360px] left-1/2 w-[710px] min-h-[200px] rounded-sm shadow-md">
      <div className="flex content-between">
        <h2 className="text-2xl px-8 py-4">{t("Create deduction")}</h2>
        {/* <CloseIcon /> */}
      </div>
      <div className="px-8 py-4">
        <div className="flex gap-2">
          <div className="w-[200px]">
            <Controller
              name="value"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={t("Deduction percent")}
                  variant="outlined"
                  error={!!fieldState.error}
                  size="small"
                  fullWidth
                  {...field}
                />
              )}
              rules={{
                required: "Required field",
                min: {
                  value: 1,
                  message: "From 1 to 12",
                },
                max: {
                  value: 12,
                  message: "From 1 to 12",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("Only numbers"),
                },
              }}
            />
          </div>
          <div>
            <Controller
              name="fromDate"
              control={control}
              render={({ field, fieldState }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label={t("Start date")}
                    inputFormat="dd.MM.yyyy"
                    minDate={
                      new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
                    }
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!fieldState.error}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
              rules={{
                required: t("Required field"),
                validate: (value) =>
                  value.getTime() >= new Date().getTime() ||
                  "Date must be greater than today",
              }}
            />
          </div>
          <div>
            <Controller
              name="toDate"
              control={control}
              render={({ field, fieldState }) => (
                <DesktopDatePicker
                  label={t("End date")}
                  inputFormat="dd.MM.yyyy"
                  minDate={
                    fromDate?.getTime && new Date(fromDate) > new Date()
                      ? new Date(fromDate?.getTime() + 1 * 24 * 60 * 60 * 1000)
                      : new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
                  }
                  {...field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!fieldState.error}
                      size="small"
                    />
                  )}
                />
              )}
              rules={{
                required: t("Required field"),
                validate: (value) =>
                  (value.getTime() > new Date().getTime() &&
                    value.getTime() > fromDate) ||
                  "Date must be greater than today and start date",
              }}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <ul>
            {Object.keys(errors) &&
              Object.keys(errors).map((name) => (
                <li className="text-red-500">{`${errors?.[name]?.message}`}</li>
              ))}
          </ul>
        </div>
      </div>
      <div className="p-8 flex gap-4 justify-end">
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          {!!deduction ? t("Save") : t("Create")}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            onClose && onClose();
          }}
        >
          {t("Cancel")}
        </Button>
      </div>
      {error && <pre className="px-8 py-4 text-red-500">{error.message}</pre>}
    </div>
  );
}

export default DeductionModal;
