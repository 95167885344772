import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BasicTabs() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", padding: "0px 25px" }}
      >
        <Tabs
          value={location.pathname}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("Dashboard")} value="/dashboard" />
          <Tab label={t("Receipts")} value="/receipts" />
          <Tab label={t("Consumers")} value="/users" />
          <Tab label={t("Lotteries")} value="/lotery" />
          <Tab label={t("Deductions")} value="/taxes" />
          <Tab label={t("Reports")} value="/reports" />
          <Tab label={t("Requests")} value="/appeals" />
          {/* <Tab label="НАСТРОЙКА ФРОДА" value='/frod' /> */}
        </Tabs>
      </Box>
    </Box>
  );
}
