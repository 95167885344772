import { makeAutoObservable } from "mobx";
import { ILotteriesResponse } from "types";
import RootStore from "store";
import Form from 'store/classes/GridStore';

class LotteriesStore {
  root
  form
  lotteries: ILotteriesResponse | null = null;

  constructor(root: typeof RootStore) {
    this.root = root;
    this.form = new Form();
    makeAutoObservable(this);
  }

  loadLotteries = async () => {
    const data = await this.root.api.get(`/api/v1/lotteries` + this.form.searchParams);
    this.lotteries = data;
  }
}

export default LotteriesStore;
