import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Cash Register": "Cash Register",
      Consumers: "Consumers",
      hints: {
        CODE_GENERATED:
          "An application for registration/authorization has been submitted,\n the code has been generated and sent to the user",
        PRE_REGISTRATION:
          "The user has not yet confirmed the registration,\n the code has not been generated",
        REGISTRATION_NOT_COMPLETED:
          "Registration not completed, action code has expired.",
        PHONE_REGISTRATION_COMPLETED:
          "Registration completed successfully by phone",
        FULL_REGISTRATION_COMPLETED:
          "Registration successfully completed by phone and e-mail",
        BLOCKED: "Buyer self-blocked",
        BLOCKED_FRAUD:
          "Blocking by fraud (automatically by an algorithm or by an admin from the Admin panel)",
        EMAIL_REGISTRATION_COMPLETED:
          "Registration completed successfully by email",
      },
      NEW: "Open",
      IN_WORK: "In progress",
      CLOSED: "Closed",
      SENT_PENALTY: "A fine was issued",
      SENT_WARNING: "A warning was issued",
      ALL: "All",
      data: "Data",
      address: "Address",
      site: "Web-site",
      name: "Name",
      close: "Close",
      save: "Save",
    },
  },
  ru: {
    translation: {
      Motivation: "Мотивация",
      Dashboard: "Cтатистика",
      Receipts: "Чеки",
      Consumers: "Покупатели",
      Lotteries: "Лотереи",
      Deductions: "Вычеты",
      Reports: "Отчеты",
      Requests: "Обращения",
      Request: "Обращение",
      "Receipt number": "Номер чека",
      Date: "Дата",
      TIN: "ИИН",
      "Cash Register": "ККТ",
      Address: "Адрес",
      Customer: "Покупатель",
      "Phone number / E-mail": "Телефон / E-mail",
      Organization: "Организация",
      Search: "Поиск",
      "Receipt amount": "Сумма чека",
      "Tax deduction": "Вычет",
      Status: "Статус",
      "Registration date": "Дата регистрации",
      "Phone number": "Телефон",
      Phone: "Телефон",
      "Receipts count": "Количество чеков",
      "Lottery wins count": "Количество выигрышей",
      "Total tax deduction": "Общий вычет",
      Withdrawn: "Выведено",
      Balance: "Баланс",
      Lottery: "Лотерея",
      "Lottery number": "Номер лотереи",
      "Lottery date": "Дата лотереи",
      Type: "Тип",
      "Lottery amount": "Сумма лотереи",
      Period: "Период",
      Prize: "Приз",
      "Prize amount": "Сумма приза",
      "Prize fund": "Призовой фонд",
      "Create lottery": "Создать лотерею",
      Edit: "Редактировать",
      Regular: "Регулярная",
      "One-time": "Единоразовая",
      Irregular: "Единоразовая",
      Day: "День",
      Month: "Месяц",
      Year: "Год",
      "Lottery type": "Тип лотереи",
      "Prize value": "Сумма приза",
      "Winner count": "Количество победителей",
      "Winner contact": "Контакт победителя",
      "Winner name": "Имя победителя",
      Name: "Название",
      Description: "Описание",
      "Start date": "Дата начала",
      "End date": "Дата окончания",
      "Lottery period": "Период лотереи",
      Message: "Сообщение",
      "Custom rules": "Правила",
      "Winners count": "Количество победителей",
      Budget: "Бюджет",
      "Money prize": "Денежный приз",
      Conditions: "Условия",
      Next: "Далее",
      Back: "Назад",
      Save: "Сохранить",
      Cancel: "Отмена",
      Update: "Обновить",
      Close: "Закрыть",
      "Deduction percent": "Процент вычета",
      "Add deduction": "Добавить вычет",
      "Violation type": "Тип нарушения",
      Details: "Данные",
      "Acceptance type": "Способ формирования сообщения",
      Open: "Открытые",
      NEW: "Новое",
      IN_WORK: "В работе",
      CLOSED: "Завершено",
      SENT_PENALTY: "Выписан штраф",
      SENT_WARNING: "Выдано предостережение",
      "QR code": "QR-идентификатор",
      "URL address": "Адрес интернет страницы",
      "By photo": "Фотография информации",
      ALL: "Все",
      data: "Данные",
      address: "Адрес",
      site: "Веб-сайт",
      name: "Наименование",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

window.addEventListener("message", (e) => {
  if (e.data === "changeLanguage.en" || e.data === "changeLanguage.ru") {
    i18n.changeLanguage(e.data.split(".")[1] || "en");
  }
});

// @ts-ignore
window.i18n = i18n;

export default i18n;
