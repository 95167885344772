import LotteryStore from "store/LotteryStore";
import AuthStore from "store/AuthStore";
import ApiStore from "store/ApiStore";
import LotteriesStore from "store/LotteriesStore";
import Users from "store/UsersStore";
import AppealsStore from "./AppealsStore";

class RootStore {
  lotery;
  auth;
  api;
  lotteries;
  users;
  appeals;

  constructor() {
    this.lotery = new LotteryStore(this);
    this.auth = new AuthStore(this);
    this.api = new ApiStore(this);
    this.lotteries = new LotteriesStore(this);
    this.users = new Users(this);
    this.appeals = new AppealsStore(this);
  }
}

export default new RootStore();
