import React, { useEffect, useState, useReducer } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import Table from "components/Table/Table";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import useFetch from "use-http";
import LotteryModal from "pages/Lottery/LotteryModal";
import { formatDate } from "utils/formatDate";
import { useNavigate } from "react-router-dom";
import makeSearchString from "utils/makeSearchString";
import { useTranslation } from "react-i18next";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LotteryModalEdit from "./LotteryModalEdit";
import UiBadge from "../../components/Badge/Badge";
import UiPagination from "../../components/Pagination/Pagination";
import { Pageable } from "../../types/pagination";
import { ILotteryResponse } from "../../types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface Props {}

interface IObject {
  [key: string]: any;
}

interface IFilter {
  status?: string;
  id?: string;
  endDate?: string;
  receiptCount?: string;
  winnersCount?: string;
  budget?: string;
}

const INITIAL_STATE = {
  status: "",
  id: "",
  endDate: null,
  lotteryType: "",
  receiptCount: "",
  winnersCount: "",
  budget: "",
};

function formReducer(state: any, action: any) {
  switch (action.type) {
    case "set":
      return { ...state, [action.name]: action.value };
    case "reset":
      return INITIAL_STATE;
    default:
      return state;
  }
}

function Lottery(props: Props) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<Pageable<ILotteryResponse> | null>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [open, setOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [currentLotteryId, setCurrentLotteryId] = useState<null | number>(null);
  const { get, response, loading, error } = useFetch();
  const [sort, setSort] = useState({
    column: "lotteryStatus",
    direction: "DESC",
  });
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);

  useEffect(() => {
    loadData();
  }, [page, sort, limit]);

  async function loadData(params?: IObject) {
    const params_req: IFilter = {};
    if (state.status) params_req["status"] = state.status;
    if (state.id) params_req["id"] = state.id;
    if (state.endDate)
      params_req["endDate"] = formatDate(state.endDate, "yyyy-MM-dd");
    if (state.receiptCount) params_req["receiptCount"] = state.receiptCount;
    if (state.winnersCount) params_req["winnersCount"] = state.winnersCount;
    if (state.budget) params_req["budget"] = `${Number(state.budget) * 100}`;

    const search_params = params
      ? makeSearchString({
          ...params,
          sortBy: sort.column,
          direction: sort.direction,
        })
      : makeSearchString({
          ...params_req,
          sortBy: sort.column,
          order: sort.direction,
        });

    const initialData = await get(
      `/api/v1/lotteries?page=${page}&limit=${limit}` +
        (search_params ? `&${search_params}` : "") +
        (!search_params.includes("status=")
          ? "&status=PAST&status=CURRENT&status=UPCOMING"
          : "")
    );
    if (response.ok) setData(initialData);
  }

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChange = (name: string, value: string | Date | null) => {
    dispatch({ type: "set", name, value });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("Status"),
        accessor: "lotteryStatus",
        Cell: ({ value }: { value: string }) => {
          if (value === "CURRENT")
            return (
              <div className="w-full">
                <UiBadge type="success">{t("ACTIVE")}</UiBadge>
              </div>
            );
          if (value === "PARTICIPANTS_REQUEST")
            return (
              <div className="w-full">
                <UiBadge type="success">{t("ACTIVE")}</UiBadge>
              </div>
            );
          if (value === "PAST")
            return (
              <div className="w-full">
                <UiBadge type="neutral">{t("PAST")}</UiBadge>
              </div>
            );
          if (value === "UPCOMING")
            return (
              <div className="w-full">
                <UiBadge type="information">{t("UPCOMING")}</UiBadge>
              </div>
            );
          return <span>{value}</span>;
        },
      },
      {
        Header: t("Lottery number"),
        accessor: "id",
      },
      {
        Header: t("Lottery date"),
        accessor: "endDate",
        Cell: ({ value }: { value: string }) => {
          return <span>{formatDate(value)}</span>;
        },
      },
      {
        Header: t("Type"),
        accessor: "lotteryType",
        Cell: ({ value }: { value: string }) => {
          let type = "";
          if (value === "REGULAR") type = t("Regular");
          if (value === "IRREGULAR") type = t("Irregular");
          return <span>{type}</span>;
        },
      },
      {
        Header: t("Period"),
        accessor: "lotteryPeriod",
        Cell: ({ value }: { value: string }) => {
          let type = "";
          if (value === "DAILY") type = t("Day");
          if (value === "WEEKLY") type = t("Week");
          if (value === "MONTHLY") type = t("Month");
          if (value === "QUARTERLY") type = t("Quarter");
          if (value === "YEARLY") type = t("Year");
          return <span>{type}</span>;
        },
      },
      {
        Header: t("Receipts count"),
        accessor: "receiptCount",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span>0</span>;
          if (value === 0) return <span>0</span>;
          return <span>{value.toLocaleString("ru-RU")}</span>;
        },
      },
      // {
      //   Header: "Ко-во победителей",
      //   accessor: "winnersCount",
      //   Cell: ({ value }: { value: number }) => {
      //     if (!value) return <span>0</span>;
      //     if (value === 0) return <span>0</span>;
      //     return <span>{value.toLocaleString("ru-RU")}</span>;
      //   },
      // },
      {
        Header: t("Prize fund"),
        accessor: "budget",
        Cell: ({ value }: { value: number }) => {
          if (!value) return <span></span>;
          return (
            <span>
              {(value / 100).toLocaleString("ru-RU", {
                maximumFractionDigits: 0,
              })}
            </span>
          );
        },
      },
      {
        Header: t("Action"),
        accessor: "action",
        Cell: (props: any) => {
          return (
            <Button
              variant="contained"
              endIcon={<ModeEditIcon />}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                setCurrentLotteryId(props.row.original.id);
                setEditOpen(true);
              }}
            >
              {t("Edit")}
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="h-full">
      <div className="bg-white rounded-md m-4 p-2">
        <div className="flex justify-between gap-4 p-3 px-5 relative">
          <div className="w-48">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Status")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Статус"
                onChange={(e) => handleChange("status", e.target.value)}
                value={state.status}
                fullWidth
              >
                <MenuItem value="CURRENT">{t("Active")}</MenuItem>
                <MenuItem value="PAST">{t("Past")}</MenuItem>
                <MenuItem value="UPCOMING">{t("Upcoming")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label={t("Lottery number")}
              variant="outlined"
              size="small"
              onChange={(e) => handleChange("id", e.target.value)}
              value={state.id}
            />
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={t("Lottery date")}
                inputFormat="dd.MM.yyyy"
                value={state.endDate}
                onChange={(value) => handleChange("endDate", value)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </div>
          {
            // <div>
            //   <TextField
            //     id="outlined-basic"
            //     label="Кол-во чеков"
            //     variant="outlined"
            //     size="small"
            //     onChange={(e) => handleChange("receiptCount", e.target.value)}
            //     value={state.receiptCount}
            //   />
            // </div>
            // <div>
            //   <TextField
            //     id="outlined-basic"
            //     label="Победителей"
            //     variant="outlined"
            //     size="small"
            //     onChange={(e) => handleChange("winnersCount", e.target.value)}
            //     value={state.winnersCount}
            //   />
            // </div>
            // <div>
            //   <TextField
            //     id="outlined-basic"
            //     label="Призовой фонд"
            //     variant="outlined"
            //     size="small"
            //     onChange={(e) => handleChange("budget", e.target.value)}
            //     value={state.budget}
            //   />
            // </div>
          }
          <div className="flex gap-2">
            <div>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setPage(1);
                  loadData();
                }}
                fullWidth
              >
                {t("Search")}
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  dispatch({ type: "reset" });
                  loadData(INITIAL_STATE);
                }}
              >
                <CloseIcon />
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                size="large"
                fullWidth
                className="whitespace-nowrap"
              >
                {t("Create lottery")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="m-4">
          <Table
            data={data?.content || []}
            columns={columns}
            handleSortChange={(column, direction) => {
              setSort({
                column: column ? column : "lotteryStatus",
                direction: direction ? direction : "DESC",
              });
            }}
            handleRowClick={(row) => {
              navigate(`/lottery/${row.id}`);
            }}
          />
          <UiPagination
            totalElements={data?.totalElements || 1}
            page={page}
            size={limit}
            onPageChange={(page: number) => {
              handleChangePage(page);
            }}
            onSizeChange={(size: number) => {
              setLimit(size);
            }}
          />
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={t("Create lottery")}
        aria-describedby={t("Create lottery")}
      >
        <LotteryModal
          onClose={() => {
            setOpen(false);
            loadData();
          }}
        />
      </Modal>
      <Modal
        open={openEdit}
        onClose={() => setEditOpen(false)}
        aria-labelledby={t("Edit")}
        aria-describedby={t("Edit")}
      >
        <LotteryModalEdit
          currentLotteryId={currentLotteryId}
          onClose={async () => {
            setEditOpen(false);
            await loadData();
          }}
        />
      </Modal>
    </div>
  );
}

export default Lottery;
