import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface ThemeOptions {}
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "#9FB8EB",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "2px 8px",
          legend: {
            fontSize: "13px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: "1.7rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          textTransform: "none",
          padding: "8px 18px",
          borderRadius: "4px",
          color: "#fff",
        },
        outlined: {
          color: "#9FB8EB",
        },
        text: {
          color: "#9FB8EB",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          MuiTab: {
            styleOverrides: {
              root: {
                fontWeight: 600,
              },
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          padding: "18px 16px",
          "&.Mui-selected": {
            backgroundColor: "#9FB8EB",
            color: "#fff",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: "scroll",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          justifyContent: "space-between",
        },
      },
    },
  },
});
