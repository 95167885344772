import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Navigation from "components/Navigation/Navigation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ru } from "date-fns/locale";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "styles/theme";
import { Provider } from "use-http";
import { options } from "api/options";
import { inIframe } from "api/options";
import { useTranslation } from "react-i18next";

import Dashboard from "pages/Dashboard/Dashboard";
import Receipts from "pages/Receipts/Receipts";
import Users from "pages/Users/Users";
import Lottery from "pages/Lottery/Lotteries";
import LotteryDetail from "pages/Lottery/LotteryDetail";
import Taxes from "pages/Taxes/Taxes";
import Settings from "pages/Settings/Settings";
import Reports from "pages/Reports/Reports";
import Frod from "pages/Frod/Frod";
import Login from "pages/Login/Login";

import store from "store";

import "./App.css";
import "./vars.css";
import Appeals from "./pages/Appeals/Appeals";

function App() {
  const isInIframe = inIframe();
  const { t } = useTranslation();
  store.auth.getTokensFromLocalStorage();
  return (
    <Provider options={options}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <div className="bg-slate-100 h-screen relative">
              {/*{!isInIframe && <Sidebar />}*/}
              <div className="h-full">
                <Navigation />
                <div className="pt-0 h-full box-border">
                  <Routes>
                    <Route path="/">
                      <Route path="/" element={<Navigate to="/dashboard" />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="receipts" element={<Receipts />} />
                      <Route path="users" element={<Users />} />
                      <Route path="lotery" element={<Lottery />} />
                      <Route path="lottery/:id" element={<LotteryDetail />} />
                      <Route path="taxes" element={<Taxes />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="reports" element={<Reports />} />
                      <Route path="frod" element={<Frod />} />
                      <Route path="login" element={<Login />} />
                      <Route path="appeals" element={<Appeals />} />
                    </Route>
                  </Routes>
                </div>
              </div>
            </div>
            <ToastContainer autoClose={5000} />
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

const Sidebar = () => (
  <div className="fixed top-0 left-0 h-full w-[80px] bg-white border-r-2 pt-5">
    <div className="m-auto w-[60px] h-[60px] bg-[url(./assets/svg/logo-gns.svg)] bg-contain bg-no-repeat"></div>
    <div className="pt-5">
      <div className="py-5 cursor-pointer hover:bg-blue-700">
        <div className="m-auto w-6 h-6 bg-[url(./assets/svg/map-pin.svg)] bg-contain bg-no-repeat"></div>
        <div className="text-white text-center text-sm">Карта</div>
      </div>
      <div className="py-5 cursor-pointer hover:bg-blue-700">
        <div className="m-auto w-5 h-5 bg-[url(./assets/svg/folder.svg)] bg-contain bg-no-repeat"></div>
        <div className="text-white text-center text-sm">Реестр</div>
      </div>
      <div className="py-5 cursor-pointer hover:bg-blue-700">
        <div className="m-auto w-5 h-5 bg-[url(./assets/svg/markets.svg)] bg-contain bg-no-repeat"></div>
        <div className="text-white text-center text-sm">Рынки</div>
      </div>
      <div className="py-5 cursor-pointer hover:bg-blue-700">
        <div className="m-auto w-5 h-5 bg-[url(./assets/svg/reports.svg)] bg-contain bg-no-repeat"></div>
        <div className="text-white text-center text-sm">Отчеты</div>
      </div>
      <div className="py-5 cursor-pointer">
        <div className="m-auto w-6 h-6 bg-[url(./assets/svg/cart.svg)] bg-contain bg-no-repeat"></div>
        <div className="text-white text-center text-sm">Мотивация</div>
      </div>
    </div>
  </div>
);

export default App;
