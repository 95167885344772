import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import RootStore from "store";
import GridStore from "./classes/GridStore";
import { AppealStatusType, IAppeal, IAppealEditForm } from "../types/appeals";
import { Pageable } from "../types/pagination";

class AppealsStore extends GridStore {
  root;
  appeals: Pageable<IAppeal> | null = null;
  rawStats: { status: AppealStatusType; number: number }[] | null = null;

  get stats() {
    return this.rawStats?.reduce((acc, stat) => {
      acc[stat.status] = stat.number;
      return acc;
    }, {} as Partial<Record<AppealStatusType, number>>);
  }

  constructor(root: typeof RootStore) {
    super({
      defaultValues: {
        page: 1,
        size: 20,
        status: "",
        text: "",
        acceptType: "",
      },
    });
    this.root = root;
    makeObservable(this, {
      appeals: observable,
      rawStats: observable,
      loadAppeals: action,
      loadSingleAppeal: action,
      loadStats: action,
      updateAppeal: action,
      stats: computed,
    });
  }

  loadAppeals = async () => {
    const appeals = await this.root.api.get(
      `/api/v1/appeal/registry` + this.searchParams
    );
    runInAction(() => (this.appeals = appeals));
  };

  loadSingleAppeal = async (id: IAppeal["id"]): Promise<IAppeal> => {
    return this.root.api.get(`/api/v1/appeal/${id}`);
  };

  loadStats = async () => {
    const rawStats = await this.root.api.get(`/api/v1/appeal/status/stats`);
    runInAction(() => (this.rawStats = rawStats));
  };

  updateAppeal = async (form: IAppealEditForm): Promise<IAppeal["id"]> => {
    return this.root.api.post(`/api/v1/appeal/update`, form, {}, false);
  };
}

export default AppealsStore;
