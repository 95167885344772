import React from "react";
import { useNavigate } from "react-router-dom";


const Login = () => {
  const navigate = useNavigate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    localStorage.setItem("TOKENS", e.target[0].value);
    navigate("/dashboard");
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <form onSubmit={handleSubmit} className="flex w-[500px] flex-col">
        <textarea
          placeholder="Token JSON"
          className="border border-gray-300 p-2 rounded mb-2"
        />
        <button
          type="submit"
          className="bg-blue-600 text-white p-2 rounded"
        >
          Сохранить
        </button>
      </form>
    </div>
  );
}

export default Login;
