import React from "react";
import { useTranslation } from "react-i18next";

function Reports() {
  const { i18n } = useTranslation();
  const prefix = i18n.language === "ru" ? "" : "_en" ;

  return (
    <div>
      <iframe
        width={"100%"}
        height={window.innerHeight - 120}
        title="Dashboard"
        src={`https://jrs.codeforensics.tech/jasperserver/flow.html?_flowId=viewReportFlow&_flowId=viewReportFlow&ParentFolderUri=%2FMotivation&reportUnit=%2FMotivation%2FReceiptStats${prefix}&standAlone=true&decorate=no&j_username=user_ro&j_password=AbjSlbFbR6vjIX6`}
      />
    </div>
  );
}

export default Reports;
