import { makeAutoObservable } from "mobx";
import RootStore from "store";
import {ITokenResponse} from "types";

class AuthStore {
  root
  isAuth: boolean = false;
  token: string = "";
  refreshToken: string = "";
  accessTokenExpired: string = "";
  refreshTokenExpired: string = "";
  constructor(root: typeof RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  logout = () => {
    this.isAuth = false;
    this.token = "";
    this.refreshToken = "";
  };

  getTokensFromLocalStorage = () => {
    const TOKENS = localStorage.getItem("TOKENS") || '{}';
    try {
      const { accessToken, accessTokenTTL, refreshToken, refreshTokenTTL } = JSON.parse(TOKENS);
      this.token = accessToken;
      this.refreshToken = refreshToken;
      this.accessTokenExpired = accessTokenTTL;
      this.refreshTokenExpired = refreshTokenTTL;
      this.isAuth = true;
    } catch (error) {
      this.logout();
    }
  }

  setTokens = (data: ITokenResponse) => {
    const { accessToken, accessTokenTTL, refreshToken, refreshTokenTTL } = data;
    this.token = accessToken;
    this.refreshToken = refreshToken;
    this.accessTokenExpired = accessTokenTTL;
    this.refreshTokenExpired = refreshTokenTTL;
    this.isAuth = true;
    localStorage.setItem("TOKENS", JSON.stringify(data));
  }

  fetchRefreshToken = async (response: any): Promise<any> => {
    const refresh_response = await this.root.api.post("/auth/refresh", {
      refreshToken: this.refreshToken,
    });
    if (refresh_response.ok) {
      this.setTokens(refresh_response.data);
      return this.root.api.fetch(response.url, response.options);
    }
    if (refresh_response.status === 401) {
      this.logout();
    }
    return Promise.reject(response);
  }
}

export default AuthStore;
