import React, { useState, useEffect, useReducer } from "react";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Table from "components/Table/Table";
import useFetch from "use-http";
import DeductionModal from "pages/Taxes/DeductionModal";
import Modal from "@mui/material/Modal";
import { formatDate } from "utils/formatDate";
import makeSearchString from "utils/makeSearchString";
import { useTranslation } from "react-i18next";
import UiPagination from "../../components/Pagination/Pagination";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

interface Props {}
interface IObject {
  [key: string]: any;
}

const INITIAL_STATE = {
  value: "",
  start: null,
};

function formReducer(state: any, action: any) {
  switch (action.type) {
    case "set":
      return { ...state, [action.name]: action.value };
    case "reset":
      return INITIAL_STATE;
    default:
      return state;
  }
}

function Taxes(props: Props) {
  const { t } = useTranslation();
  const [data, setData] = useState({ items: [], total: 0 });
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [open, setOpen] = useState(false);
  const { get, response, loading, error } = useFetch();
  const [sort, setSort] = useState({ column: "dateTime", direction: "asc" });
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [deduction, setDeduction] = useState(null);

  useEffect(() => {
    loadData();
  }, [page, sort, limit]);

  async function loadData(params?: IObject) {
    const string_params = params
      ? makeSearchString({
          ...params,
          sortBy: sort.column,
          direction: sort.direction,
        })
      : makeSearchString({
          ...state,
          start: state.start
            ? formatDate(new Date(state.start), "dd.MM.yyyy")
            : "",
          sortBy: sort.column,
          direction: sort.direction,
        });

    const initialData = await get(
      `/api/v1/deductions?page=${page}&limit=${limit}` +
        (string_params ? `&${string_params}` : "")
    );
    if (response.ok) setData(initialData);
  }

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleChange = (name: string, value: string | Date | null) => {
    dispatch({ type: "set", name, value });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("Deduction percent"),
        accessor: "value",
        disableSortBy: true,
      },
      {
        Header: t("Start date"),
        accessor: "fromDate",
        disableSortBy: true,
        Cell: ({ value }: { value: any }) => (
          <span>{formatDate(value, "dd.MM.yyy")}</span>
        ),
      },
      {
        Header: t("End date"),
        accessor: "toDate",
        disableSortBy: true,
        Cell: ({ value }: { value: any }) => (
          <span>{formatDate(value, "dd.MM.yyy")}</span>
        ),
      },
      // {
      //   Header: "",
      //   accessor: "col3",
      //   Cell: () => (
      //     <div className="cursor-pointer">
      //       <ModeEditIcon color="primary" />
      //     </div>
      //   ),
      // },
    ],
    []
  );

  return (
    <>
      <div className="h-full">
        <div className="bg-white rounded-md m-4 p-2">
          <div className="flex justify-between gap-4 p-3 px-5">
            <div className="flex gap-4">
              <div>
                <TextField
                  id="outlined-basic"
                  label={t("Deduction percent")}
                  variant="outlined"
                  size="small"
                  onChange={(e) => handleChange("value", e.target.value)}
                  value={state.value}
                />
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label={t("Start date")}
                    inputFormat="dd.MM.yyyy"
                    value={state.start}
                    onChange={(value) => handleChange("start", value)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="flex gap-2">
                <div>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => {
                      setPage(1);
                      loadData();
                    }}
                    fullWidth
                  >
                    {t("Search")}
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                      dispatch({ type: "reset" });
                      loadData(INITIAL_STATE);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </div>
            </div>

            <div className="w-[400px]">
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => setOpen(true)}
              >
                {t("Add deduction")}
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="m-4 overflow-x-auto">
            <Table
              data={data?.items || []}
              columns={columns}
              handleSortChange={(column, direction) => {
                setSort({ column, direction });
              }}
              handleRowClick={(row) => {
                setDeduction(row);
                setOpen(true);
              }}
            />
            <UiPagination
              totalElements={data?.total || 1}
              page={page}
              size={limit}
              onPageChange={(page: number) => {
                setPage(page);
              }}
              onSizeChange={(size: number) => {
                setLimit(size);
              }}
            />
          </div>
        </div>
        {/*<div className="fixed bottom-0 p-4 bg-white w-full drop-shadow-xl">*/}
        {/*  <Pagination*/}
        {/*    count={data?.total ? Math.ceil(data?.total / 20) : 1}*/}
        {/*    page={page}*/}
        {/*    onChange={handleChangePage}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setDeduction(null);
        }}
        aria-labelledby={t("Create deduction")}
        aria-describedby={t("Create deduction")}
      >
        <DeductionModal
          onClose={() => {
            setOpen(false);
            setDeduction(null);
            loadData();
          }}
          deduction={deduction}
        />
      </Modal>
    </>
  );
}

export default Taxes;
