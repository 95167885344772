import {
  AppealStatusType,
  AppealStatusTypeMapping,
  IAppeal,
} from "../../types/appeals";
import React, { useEffect, useState } from "react";
import { AppealCard } from "../../models/AppealCard";
import { useTranslation } from "react-i18next";
import store from "../../store";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import UiBadge from "../../components/Badge/Badge";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { observer } from "mobx-react-lite";

const AppealModal = ({
  handleClose,
  appealId,
}: {
  handleClose: () => void;
  appealId: IAppeal["id"] | null;
}) => {
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appeal, setAppeal] = useState<AppealCard>();
  const { t } = useTranslation();
  const { loadSingleAppeal, updateAppeal } = store.appeals;

  useEffect(() => {
    if (!appealId) return;
    const getCurrentAppeal = async () => await loadSingleAppeal(appealId);
    getCurrentAppeal()
      .then((appeal) => setAppeal(new AppealCard(appeal)))
      .catch((error) => setError(error));
  }, [appealId]);

  const onUpdateAppeal = async () => {
    try {
      appeal && (await updateAppeal(appeal.getForm()));
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const convertImage = (img: string) => "data:image/jpeg;base64, " + img;

  return (
    <Dialog open={!!appealId} onClose={() => handleClose()}>
      {appeal?.rawAppeal && (
        <>
          <DialogTitle>
            <div className="flex gap-2 items-center">
              {t("Request")}
              <UiBadge type={AppealStatusTypeMapping[appeal.rawAppeal.status]}>
                {t(`${appeal?.rawAppeal.status}`)}
              </UiBadge>
            </div>
          </DialogTitle>
          <DialogContent sx={{ width: 600 }}>
            <div className="pt-2 pb-4 text-[14px]">
              {appeal?.rawAppeal?.appealText}
            </div>
            <div className="pt-2 pb-4 text-[16px] font-bold">{t("data")}</div>
            {appeal?.rawAppeal.address && (
              <div className="flex justify-between gap-1 pt-2 pb-4 text-[14px] font-medium">
                <span>{t("address")}</span>
                <span>{appeal?.rawAppeal.address}</span>
              </div>
            )}
            {appeal.rawAppeal.url && (
              <div className="flex justify-between gap-1 pt-2 pb-4 text-[14px] font-medium">
                <span>{t("site")}</span>
                <span>{appeal?.rawAppeal.url}</span>
              </div>
            )}
            {appeal?.rawAppeal.photos?.slice(0, 1).map((photo, i) => (
              <img className="pb-8" src={convertImage(photo)} alt="" key={i} />
            ))}
            <div className="pt-2 pb-8">
              <TextField
                label={t("TIN")}
                type="number"
                value={appeal.form.controls.tin.value || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  appeal && (appeal.form.controls.tin.value = e.target.value)
                }
                onBlur={() => appeal.form.controls.tin.setDirty(true)}
                error={
                  appeal.form.controls.tin.dirty &&
                  !!appeal.form.controls.tin.errors.length
                }
                fullWidth
              />
            </div>
            <div className="pt-2 pb-8">
              <TextField
                label={t("name")}
                value={appeal?.rawAppeal.sellerName || ""}
                disabled
                fullWidth
              />
            </div>
            <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={t("Status")}
              onChange={(e: SelectChangeEvent<AppealStatusType>) => {
                appeal &&
                  (appeal.form.controls.status.value = e.target
                    .value as AppealStatusType);
              }}
              value={appeal.form.controls.status.value}
              onBlur={() => appeal.form.controls.status.setDirty(true)}
              error={
                appeal.form.controls.status.dirty &&
                !!appeal.form.controls.status.errors.length
              }
              fullWidth
            >
              <MenuItem value="NEW">{t("NEW")}</MenuItem>
              <MenuItem value="IN_WORK">{t("IN_WORK")}</MenuItem>
              <MenuItem value="SENT_PENALTY">{t("SENT_PENALTY")}</MenuItem>
              <MenuItem value="SENT_WARNING">{t("SENT_WARNING")}</MenuItem>
              <MenuItem value="CLOSED">{t("CLOSED")}</MenuItem>
            </Select>
            {error && <Alert severity="error">{error}</Alert>}
          </DialogContent>
          <DialogActions>
            <div className="pb-4">
              <Button
                onClick={onUpdateAppeal}
                color="primary"
                size="large"
                variant="contained"
                disabled={
                  appeal.form.invalid &&
                  appeal.form.controls.status.value !== "CLOSED"
                }
              >
                {t("Update")}
              </Button>
              <Button
                onClick={() => handleClose()}
                color="primary"
                size="large"
                variant="text"
              >
                {t("Close")}
              </Button>
            </div>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default observer(AppealModal);
