import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";
import store from "store";
import Table from "components/Table/Table";
import { formatDate } from "utils/formatDate";
import { useTranslation } from "react-i18next";

function LotteryDetail() {
  const { loadLotteryDetail } = store.lotery;
  const { id } = useParams<{ id: string }>();
  React.useEffect(() => {
    if (id) loadLotteryDetail(id);
  }, [id, loadLotteryDetail]);

  return (
    <div>
      <LotteryDetailHeader />
      <LotteryDetailFilters />
      <LotteryDetailTable />
    </div>
  );
}

const LotteryDetailHeader = observer(() => {
  const { lotery } = store.lotery;
  const { t } = useTranslation();
  return (
    <div className="text-slate-700 bg-white m-4 px-4 py-8 rounded-md grid grid-cols-[1fr_1fr_2fr_1fr] gap-4 px-8">
      <div className="p-4 rounded-md border-[1px] border-slate-200">
        <div className="">{t("Lottery number")}</div>
        <div className="text-2xl font-bold">{lotery?.id}</div>
      </div>
      <div className="p-4 rounded-md border-[1px] border-slate-200">
        <div className="">{t("Lottery date")}</div>
        <div className="text-2xl font-bold">
          {formatDate(lotery?.endDate, "dd.MM.yy")}
        </div>
      </div>
      <div className="p-4 rounded-md border-[1px] border-slate-200">
        <div className="">{t("Date")}</div>
        <div className="text-2xl font-bold">
          {formatDate(lotery?.startDate, "dd.MM.yy")} -{" "}
          {formatDate(lotery?.endDate, "dd.MM.yy")}
        </div>
      </div>
      <div className="p-4 rounded-md border-[1px] border-slate-200">
        <div className="">{t("Prize fund")}</div>
        <div className="text-2xl font-bold">
          {lotery?.budget
            ? Math.round(lotery?.budget / 100).toLocaleString("ru-RU")
            : "-"}
        </div>
      </div>
    </div>
  );
});

const LotteryDetailFilters = observer(() => {
  const { loadLotteryUsers, filters, handleChangeFilters } = store.lotery;
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  return (
    <div className="bg-white m-4 rounded-md grid grid-cols-5 gap-4 px-8 py-4">
      <div>
        <TextField
          id="outlined-basic"
          label={t("Prize value")}
          variant="outlined"
          size="small"
          value={filters.price}
          onChange={(e) => handleChangeFilters("price", e.target.value)}
          fullWidth
        />
      </div>
      <div>
        <TextField
          id="outlined-basic"
          label={t("Winner contact")}
          variant="outlined"
          size="small"
          value={filters.contact}
          onChange={(e) => handleChangeFilters("contact", e.target.value)}
          fullWidth
        />
      </div>
    </div>
  );
});

const LotteryDetailTable = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { loadLotteryUsers, lotteryUsers } = store.lotery;
  React.useEffect(() => {
    if (id) loadLotteryUsers(id);
  }, [id, loadLotteryUsers]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("Prize value"),
        accessor: "prize",
      },
      {
        Header: t("Winner name"),
        accessor: "fio",
      },
      {
        Header: t("Winner contact"),
        accessor: "phone",
      },
      {
        Header: t("Receipt number"),
        accessor: "receiptNumber",
      },
      {
        Header: t("Status"),
        accessor: "status",
      },
    ],
    []
  );

  return (
    <div>
      <div className="w-full">
        <div className="m-4 overflow-x-auto">
          <Table data={lotteryUsers?.items || []} columns={columns} />
        </div>
      </div>
      <div className="fixed bottom-0 p-4 bg-white w-full drop-shadow-xl">
        <Pagination
          count={lotteryUsers?.total ? Math.ceil(lotteryUsers?.total / 20) : 1}
          page={1}
        />
      </div>
    </div>
  );
});

export default observer(LotteryDetail);
