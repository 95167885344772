import RootStore from "store";
import { toast } from "react-toastify";

class ApiStore {
  root;
  constructor(root: typeof RootStore) {
    this.root = root;
  }

  fetch = async (url: string, options: RequestInit = {}) => {
    const headers = new Headers();
    headers.set("Authorization", `Bearer ${this.root.auth.token}`);
    headers.set("Content-Type", `application/json`);
    options.headers = headers;
    return fetch(url, options);
  };

  handleError = (response: any) => {
    if (response.status === 401) {
      return this.root.auth.fetchRefreshToken(response);
    }
    toast.error("Ошибка сервера");
    return Promise.reject(response);
  };

  get = async (url: string, options: RequestInit = {}) => {
    options.method = "GET";
    const response = await this.fetch(url, options);
    if (!response.ok) {
      return this.handleError(response);
    }
    return response.json();
  };

  post = async (
    url: string,
    data: Record<string, any> | FormData | string,
    options: RequestInit = {},
    jsonResponse = true
  ) => {
    options.method = "POST";
    options.body = JSON.stringify(data);
    const response = await this.fetch(url, options);
    if (!response.ok) {
      return this.handleError(response);
    }
    return jsonResponse ? response.json() : response;
  };

  put = async (
    url: string,
    data: Record<string, any> | FormData | string,
    options: RequestInit = {}
  ) => {
    options.method = "PUT";
    options.body = JSON.stringify(data);
    const response = await this.fetch(url, options);
    if (!response.ok) {
      return this.handleError(response);
    }
    return response.json();
  };

  delete = async (url: string, options: RequestInit = {}) => {
    options.method = "DELETE";
    const response = await this.fetch(url, options);
    if (!response.ok) {
      return this.handleError(response);
    }
    return response.json();
  };
}

export default ApiStore;
